/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Custom color theme properties */
:root {
  --f7-theme-color: #c23464;
  --f7-theme-color-rgb: 242, 209, 57;
  --f7-theme-color-shade: #efc813;
  --f7-theme-color-tint: #f5da5f;

	--f7-table-cell-padding-horizontal: 3px;
}

/* Invert navigation bars to fill style */
:root,
:root.theme-dark,
:root .theme-dark {
  --f7-bars-bg-color: var(--f7-theme-color);
  --f7-bars-bg-color-rgb: var(--f7-theme-color-rgb);
  --f7-bars-translucent-opacity: 0.9;
  --f7-bars-text-color: #ffffff;
  --f7-bars-link-color: #ffd814;
  --f7-navbar-subtitle-text-color: rgba(255,255,255,0.85);
  --f7-bars-border-color: transparent;
  --f7-tabbar-link-active-color: #ffd814;
  --f7-tabbar-link-inactive-color: rgba(255,255,255,0.54);
  --f7-sheet-border-color: transparent;
  --f7-tabbar-link-active-border-color: #ffd814;
}
.appbar,
.navbar,
.toolbar,
.subnavbar,
.calendar-header,
.calendar-footer {
  --f7-touch-ripple-color: var(--f7-touch-ripple-white);
  --f7-link-highlight-color: var(--f7-link-highlight-white);
  --f7-button-text-color: #ffd814;
  --f7-button-pressed-bg-color: rgba(255,255,255,0.1);
}
.navbar-large-transparent,
.navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #000;

  --r: 242;
  --g: 209;
  --b: 57;
  --progress: var(--f7-navbar-large-collapse-progress);
  --f7-bars-link-color: rgb(
    calc(var(--r) + (255 - var(--r)) * var(--progress)),
    calc(var(--g) + (255 - var(--g)) * var(--progress)),
    calc(var(--b) + (255 - var(--b)) * var(--progress))
  );
}
.theme-dark .navbar-large-transparent,
.theme-dark .navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #ffd814;
}

/* Your app custom styles here */
.block-title{
	color:#efc813 !important;
}



@media only screen and (min-width: 600px) {

	body{
	  background:#282838;
	}

	#app{
		width:600px;
		margin:0 auto;
	}

}
